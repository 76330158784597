import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../services/actions";
import AddToHome from "./AddToHome";
import AutoOrderConfirmation from "./AutoOrderConfirmation";
import CancelOrderConfirmation from "./CancelOrderConfirmation";
import Charge from "./Charge";
import DeleteUserConfirmation from "./DeleteUserConfirmation";
import DownloadNativeApplication from "./DownloadNativeApplication";
import OperationWhyModal from "./OperationWhy";
import OrderConfirmation from "./OrderConfirmation";
import RevokeConfirmation from "./RevokeConfirmation";

function ModalContainer() {
  const { visible, props } = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(hideModal());
  };

  return (
    <div>
      {visible && <style>{"html,body {overflow-y:hidden}"}</style>}
      <OrderConfirmation
        visible={visible === "orderConfirmation"}
        onClose={onClose}
        {...props}
      />
      <AutoOrderConfirmation
        visible={visible === "autoOrderConfirmation"}
        onClose={onClose}
        {...props}
      />
      <Charge visible={visible === "charge"} onClose={onClose} {...props} />
      <CancelOrderConfirmation
        visible={visible === "cancelOrderConfirmation"}
        onClose={onClose}
        {...props}
      />
      <OperationWhyModal
        visible={visible === "operationWhy"}
        onClose={onClose}
        {...props}
      />
      <RevokeConfirmation
        visible={visible === "revokeConfirmation"}
        onClose={onClose}
        {...props}
      />
      <DeleteUserConfirmation
        visible={visible === "deleteUserConfirmation"}
        onClose={onClose}
        {...props}
      />
      <DownloadNativeApplication
        visible={visible === "downloadNativeApplication"}
        onClose={onClose}
        {...props}
      />
      <AddToHome
        visible={visible === "addToHome"}
        onClose={onClose}
        {...props}
      />
    </div>
  );
}

export default ModalContainer;
