import { motion } from "framer-motion";
import { Trans, useTranslation } from "react-i18next";
import { Sheet } from "react-modal-sheet";
import LogoBar from "~/components/LogoBar";

export default function AddToHomeIOS({ onDismissAlert, isVisible }) {
  const { t, i18n } = useTranslation();

  return (
    <Sheet
      isOpen={isVisible}
      onClose={onDismissAlert}
      detent="content-height"
      tweenConfig={{ ease: "easeIn", duration: 0.3 }}
      style={{ zIndex: 50 }}
      disableScrollLocking
    >
      <Sheet.Container className="!rounded-t-3xl !bg-base-100 mb-0">
        <Sheet.Header />
        <Sheet.Scroller>
          <Sheet.Content>
            <div className="grow flex flex-col pb-5">
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
                className="flex flex-col justify-start grow gap-10 mt-3"
              >
                <motion.section
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1 }}
                  className="flex flex-col gap-5"
                >
                  <motion.img
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 1.5 }}
                    src="/img/logo/logo_bg_black_1.png"
                    className="w-24 h-24 mx-auto rounded-3xl"
                  />
                  <LogoBar />
                </motion.section>

                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 2 }}
                  className="py-4 px-4 flex flex-col"
                >
                  <div className="font-bold mb-2 text-lg">
                    {t("modals.addToHome.title")}
                  </div>
                  <motion.section
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 2.5 }}
                    className="flex flex-col gap-4 mb-20 px-4"
                  >
                    <p>
                      <Trans
                        t={t}
                        i18n={i18n}
                        i18nKey="modals.addToHome.ios.one"
                        components={{
                          b: <b />,
                          img: (
                            <img
                              src="/svg/share_box.svg"
                              className="mx-1 w-5 h-5 inline -mt-2"
                              alt="share_box"
                            />
                          ),
                        }}
                      >
                        1. with <b>Safari Browser</b> Tap on the box with
                        outgoing flash Similar to <img />
                      </Trans>
                    </p>
                    <p>
                      <Trans
                        t={t}
                        i18n={i18n}
                        i18nKey="modals.addToHome.ios.two"
                        components={{
                          span: <span className="inline font-bold" />,
                        }}
                      >
                        2.and then tap on the{" "}
                        <span key="0">Add To Home Screen</span>
                      </Trans>
                    </p>
                    <div>
                      <Trans
                        t={t}
                        i18n={i18n}
                        i18nKey="modals.addToHome.ios.three"
                        components={{ span: <span className="font-bold" /> }}
                      >
                        3. Please wait a second until icon and information shows
                        up and then tap on the <span>Add</span> button.
                      </Trans>
                    </div>
                  </motion.section>
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 3 }}
                    className="flex justify-center w-full"
                  >
                    <button
                      onClick={onDismissAlert}
                      className="btn btn-block btn-primary"
                    >
                      {t("okay")}
                    </button>
                  </motion.div>
                </motion.div>
              </motion.div>
            </div>
          </Sheet.Content>
        </Sheet.Scroller>
      </Sheet.Container>
      <Sheet.Backdrop className="backdrop-blur-sm" onTap={onDismissAlert} />
    </Sheet>
  );
}
