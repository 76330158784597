import { UAContext } from "@quentin-sommer/react-useragent";
import { useNavigate } from "@remix-run/react";
import cx from "classnames";
import httpStatus from "http-status";
import lodash from "lodash";
import { CheckCircle2, CircleCheck } from "lucide-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Sheet } from "react-modal-sheet";
import { useDispatch } from "react-redux";
import CrownIcon from "~/assets/svg/Crown";
import Modal from "~/components/Modal";
import renderDigits from "~/helpers/renderDigits";
import toastWithStyle from "~/helpers/toastWithStyle";
import { postPay } from "~/services/api";
import ceilify from "~/utils/ceilify";
import to from "~/utils/to";
import { setModalParams } from "../../services/actions";

const GATEWAYS = [
  {
    id: "zarinpal",
    icon: "/img/stripe.png",
    t: "payWithIrLocalGateway",
    subtitle: "payWithIrLocalGatewaySubtitle",
    disabled: false,
    availableTimezones: ["Asia/Tehran"],
    riskLimitAt: 4,
    supportedCurrencies: ["IRT"],
  },
  // {
  //   id: "stripe",
  //   icon: "/img/stripe.png",
  //   t: "payWithCreditCard",
  //   disabled: false,
  //   notAvailableTimezones: ["Asia/Tehran"],
  //   riskLimitAt: 4,
  //   supportedCurrencies: ["EUR", "USD"],
  // },
  {
    id: "cryptomus",
    icon: "/img/crypto.png",
    t: "payWithCrypto",
    subtitle: "payWithCryptoSubtitle",
    disabled: false,
    riskLimitAt: 6,
    supportedCurrencies: ["EUR", "USD"],
  },

  // {
  //   id: "zify",
  //   icon: "/img/stripe.png",
  //   t: "Pay with Rial",
  //   disabled: false,
  //   timezones: ["Asia/Tehran"],
  // },
];

const getFeaturedItem = (items) => {
  return lodash.findLast(items?.results, (i) => i.plan === "10");
};

const initGateway = ({ gateways, currency }) => {
  return;
};

const ChargeModal = ({
  visible,
  onClose,
  cookie,
  user,
  items,
  currency,
}: {
  visible: boolean;
  onClose: () => void;
  cookie: any;
  user: any;
  items: any;
  currency: any;
}) => {
  const featuredItem = getFeaturedItem(items);
  const [error, setError] = React.useState(null);
  const [selectedBundle, setSelectedBundle] = React.useState(featuredItem);
  const [submitting, setSubmitting] = React.useState(false);
  const [selectedGateway, setSelectedGateway] = React.useState();
  const { uaResults } = React.useContext(UAContext);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    setSelectedBundle(featuredItem);
  }, [items]);

  React.useEffect(() => {
    if (!currency) return;
    setSelectedGateway(
      lodash.find(GATEWAYS, (g) =>
        g.supportedCurrencies.includes(currency.code),
      ),
    );
  }, [currency]);

  const onSubmit = async () => {
    setError(null);
    setSubmitting(true);

    const [err, res] = await to(
      postPay({
        token: cookie.token,
        data: { _item: selectedBundle._id },
        query: { gateway: selectedGateway.id },
        headers: { "axios-to-fetch": 0 },
      }),
    );

    if (err) {
      setError(err);
      setSubmitting(false);
      if (err.response?.status === httpStatus["NOT_ACCEPTABLE"]) {
        return toastWithStyle({
          message: t("modals.charge.notAcceptable"),
          options: { type: "error" },
          uaResults,
          i18n,
        });
      }
      return toastWithStyle({
        message: err.response.data.message,
        options: { type: "error" },
        uaResults,
        i18n,
      });
    }

    setSubmitting(false);
    window.location.href = res.data.url;
    return Promise.resolve();
  };

  const renderBtnText = () => {
    if (submitting) return t("modals.charge.submitting");
    if (!selectedBundle) return t("modals.charge.selectPackage");
    if (!selectedGateway) return t("modals.charge.selectGateway");

    return (
      <Trans
        t={t}
        i18n={i18n}
        i18nKey="modals.charge.submitBtn"
        className="text-center"
        components={[
          <span
            key="0"
            className="text-neutral-900 text-base font-['Hubot-Sans', 'IRANYekanXVF'] leading-normal"
          >
            Buy{" "}
          </span>,
          <span key="1" className="text-neutral-900 font-bold leading-normal">
            {selectedBundle?.name}
          </span>,
          <span
            key="2"
            className="text-neutral-900 text-base font-['Hubot-Sans', 'IRANYekanXVF'] leading-normal"
          >
            {" "}
            for{" "}
          </span>,
          <span
            key="3"
            className={cx("text-neutral-900 flex", {
              "flex-row-reverse": i18n.dir() === "rtl",
            })}
          >
            <span key="0" className="text-xs leading-loose">
              {currency.symbol}
            </span>
            <span key="1" className="font-bold text-base">
              {renderDigits({
                data: ceilify(selectedBundle?.price * currency.rate),
                i18n,
              })}
            </span>
          </span>,
        ]}
        values={{
          name: selectedBundle?.name,
          plan: selectedBundle?.plan,
          symbol: currency.symbol,
          price: renderDigits({
            data: ceilify(selectedBundle?.price * currency.rate),
            i18n,
          }),
        }}
      />
    );
  };

  const renderGateway = (gateway) => {
    // const currentTimezone =
    //   "Asia/Tehran" || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const isCurrencySupported = gateway.supportedCurrencies.includes(
      user.settings.currency,
    );
    if (user.risk && user.risk > gateway.riskLimitAt) return null;
    if (
      gateway.availableTimezones &&
      !gateway.availableTimezones.includes(currentTimezone)
    )
      return null;
    if (
      gateway.notAvailableTimezones &&
      gateway.notAvailableTimezones.includes(currentTimezone)
    )
      return null;
    return (
      <button
        disabled={gateway.disabled || submitting || !isCurrencySupported}
        key={gateway.id}
        onClick={() => {
          setSelectedGateway(gateway);
        }}
        className={cx(
          "flex flex-col items-center justify-center gap-2 rounded-xl border-2 px-4 py-2 transition-all disabled:opacity-50",
          {
            "border-info border-opacity-100 bg-base-100":
              selectedGateway?.id === gateway.id,
            "bg-transparent": selectedGateway?.id !== gateway.id,
          },
        )}
      >
        <div className="relative flex w-full items-center justify-between py-1">
          <div className="text-sm flex flex-col items-start text-start max-w-[80%] gap-2">
            {t(`modals.charge.${gateway.t}`)}
            {isCurrencySupported && gateway.subtitle && (
              <p className="text-xs text-zinc-500">
                {t(`modals.charge.${gateway.subtitle}`)}
              </p>
            )}

            {!isCurrencySupported && (
              <p className="text-xs text-zinc-500">
                {t("modals.charge.notAcceptable")}
              </p>
            )}
          </div>
          {selectedGateway?.id === gateway.id && (
            <CheckCircle2 className="text-success" />
          )}
          {selectedGateway?.id !== gateway.id && (
            <div className="h-6 w-6 rounded-full border-2 border-base-300" />
          )}
        </div>
      </button>
    );
  };
  const Content = () => {
    if (!items) return null;
    if (!user) return null;
    if (!user.settings) return null;

    return (
      <div
        className={cx(
          "no-scrollbar relative flex border-0 bg-base-100 overflow-y-auto",
        )}
        style={{
          paddingBottom: "calc(env(safe-area-inset-bottom) + 1rem)",
        }}
      >
        <section className="flex w-full flex-col gap-3 md:pt-4 px-4 md:mt-10 pb-2">
          <h1 className="font-bold text-center text-2xl font-['Hubot-Sans', 'IRANYekanXVF']">
            {t("modals.charge.title")}
          </h1>

          <div className="my-4" />
          {featuredItem && (
            <button
              onClick={() => {
                setSelectedBundle(featuredItem);
              }}
              className={cx(
                "relative rounded-3xl h-56 px-4 py-12 flex-col justify-start items-center flex",
                {
                  "bg-cyan-300/20 shadow-2xl shadow-cyan-400/10":
                    selectedBundle?._id !== featuredItem._id,
                  "bg-cyan-300/60 shadow-2xl shadow-cyan-400/60":
                    featuredItem._id === selectedBundle?._id,
                },
              )}
            >
              <div className="absolute -top-3 h-6 px-2 py-1 bg-base-100 rounded-b-lg justify-center items-center flex">
                <div className="text-base-content text-xs font-medium font-['Roboto', 'IRANYekanXVF'] uppercase leading-none">
                  {t("modals.charge.mostPopular")}
                </div>
              </div>

              <div className="flex-col justify-start items-center gap-3 flex">
                <div className="p-1 bg-stone-50/20 rounded-lg justify-center items-center gap-2.5 flex">
                  <CrownIcon className="w-6 h-6" />
                </div>
                <div className="text-base-content text-xl font-bold font-['Google-Sans', 'IRANYekanXVF'] uppercase leading-normal">
                  {featuredItem.name}
                </div>
                <div
                  className={cx("text-base-content text-2xl font-bold flex", {
                    "flex-row-reverse": i18n.dir() === "rtl",
                  })}
                >
                  <span className="text-sm leading-loose">
                    {currency.symbol}
                  </span>
                  {renderDigits({
                    data: ceilify(featuredItem.price * currency.rate),
                    i18n,
                  })}
                </div>
              </div>
            </button>
          )}

          <div className="mt-8 mb-4 flex items-center font-['Hubot-Sans', 'IRANYekanXVF'] justify-center gap-2 text-center">
            {t("modals.charge.otherPackages")}
          </div>

          <div className="grid w-full grid-cols-2 gap-2">
            {items.results
              .filter((i) => i.plan !== "10")
              .map((item) => (
                <button
                  key={item._id}
                  onClick={() => {
                    setSelectedBundle(item);
                  }}
                  className={cx(
                    "flex flex-col items-center justify-center gap-2 rounded-xl border-2 px-2 py-3 transition-all relative",
                    {
                      "bg-transparent border-base-200":
                        selectedBundle?._id !== item._id,
                      "border-2 border-info border-opacity-100 text-base-content bg-cyan-300/60 shadow-2xl shadow-cyan-400/60":
                        item._id === selectedBundle?._id,
                    },
                  )}
                >
                  <div className="flex items-center justify-center uppercase">
                    <span className="font-['Hubot-Sans', 'IRANYekanXVF'] font-semibold">
                      {item.name}
                    </span>
                  </div>
                  <div
                    className={cx("text-xl font-extrabold flex", {
                      "flex-row-reverse": i18n.dir() === "rtl",
                    })}
                  >
                    <span className="text-sm leading-loose">
                      {currency.symbol}
                    </span>
                    <span>
                      {renderDigits({
                        data: ceilify(item.price * currency.rate),
                        i18n,
                      })}
                    </span>
                  </div>
                </button>
              ))}
          </div>

          <div className="grid w-full gap-3 pt-2 mt-auto grid-cols-1">
            {GATEWAYS.map(renderGateway)}
            <button
              onClick={() => {
                dispatch(
                  setModalParams({ target: "downloadNativeApplication" }),
                );
              }}
              className={cx(
                "flex flex-col items-center bg-transparent justify-center gap-2 rounded-xl border-2 px-4 py-2 transition-all disabled:opacity-50",
              )}
            >
              <div className="relative flex w-full items-center justify-between py-1">
                <div className="text-sm flex flex-col items-start text-start max-w-[80%] gap-2">
                  {t(`modals.charge.payWithCreditCard`)}
                  <p className="text-xs text-zinc-500">
                    {t("modals.charge.payWithCreditCardSubtitle")}
                  </p>
                </div>
              </div>
            </button>
          </div>
          <div className="w-full">
            <button
              onClick={onSubmit}
              disabled={!selectedBundle || !selectedGateway || submitting}
              className="w-full h-12 px-6 py-3 bg-cyan-200 rounded justify-center items-center gap-2 flex disabled:opacity-50"
            >
              {renderBtnText()}
            </button>
            <p className="text-center px-3 mt-2 text-sm">
              By purchasing the package, you agree with the HidSim{" "}
              <a href="/terms">Terms</a> of Use and{" "}
              <a href="/policy">Privacy Policy</a>
            </p>
          </div>
        </section>
      </div>
    );
  };

  if (uaResults.mobile) {
    return (
      <Sheet
        isOpen={visible}
        onClose={onClose}
        detent="content-height"
        tweenConfig={{ ease: "easeIn", duration: 0.3 }}
        style={{ zIndex: 50 }}
      >
        <Sheet.Container className="!rounded-t-3xl !bg-base-100">
          <Sheet.Header />
          <Sheet.Scroller>
            <Sheet.Content>
              <Content />
            </Sheet.Content>
          </Sheet.Scroller>
        </Sheet.Container>
        <Sheet.Backdrop className="backdrop-blur-sm" onTap={onClose} />
      </Sheet>
    );
  }
  return (
    <Modal
      isOpen={visible}
      onClose={onClose}
      classNames={{ dialog: "max-w-lg" }}
    >
      <Content />
    </Modal>
  );
};

export default ChargeModal;
