import dayjs from "dayjs";
import { X } from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setModalParams } from "~/containers/Modal";

const initStorageState = { count: 0, at: 0 };
export default function AddToHomeWidget({}) {
  const [addToHomeIsVisible, setAddToHomeIsVisible] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const agent = useSelector((state) => state.agent);

  React.useEffect(() => {
    let addToHomeAlert =
      localStorage.getItem("add-to-home/settings") ||
      JSON.stringify(initStorageState);
    addToHomeAlert = JSON.parse(addToHomeAlert);

    if (addToHomeAlert?.count < 10) {
      if (
        dayjs().diff(dayjs(addToHomeAlert?.at), "minutes") >
        addToHomeAlert?.count * 10
      ) {
        setAddToHomeIsVisible(true);
      }
    }
  }, []);

  const onDismissAddToHome = async () => {
    let addToHomeAlert =
      localStorage.getItem("add-to-home/settings") ||
      JSON.stringify(initStorageState);

    addToHomeAlert = JSON.parse(addToHomeAlert);

    addToHomeAlert = {
      count: Number(addToHomeAlert.count) + 1 || 0,
      at: new Date().getTime(),
    };

    await localStorage.setItem(
      "add-to-home/settings",
      JSON.stringify(addToHomeAlert),
    );
    setAddToHomeIsVisible(false);
  };

  if (!addToHomeIsVisible) return null;
  if (!agent?.metadata) return null;
  if (agent?.metadata?.standalone) return null;
  if (agent?.metadata?.instagram) return null;
  if (agent?.metadata?.webview) return null;

  return (
    <div className="w-full h-16 p-4 bg-base-200/50 backdrop-blur-3xl items-center gap-4 flex">
      <X
        size={24}
        className="p-0.5 cursor-pointer"
        onClick={onDismissAddToHome}
      />
      <div className="grow shrink basis-0 h-10 justify-start items-center gap-2 flex">
        <img
          src="/img/logo/logo_bg_black_2.png"
          className="w-10 h-10 rounded"
        />
        <div className="grow shrink basis-0 flex-col justify-center items-end flex">
          <div className="self-stretch text-neutral-950 text-sm font-semibold leading-normal">
            {t("modals.addToHome.widget.title")}
          </div>
          <div className="self-stretch text-neutral-600 text-xs font-normal leading-none">
            {t("modals.addToHome.widget.description")}
          </div>
        </div>
      </div>

      <button
        className="px-5 py-2 bg-[#a8efc0] rounded-lg font-bold"
        onClick={() => {
          dispatch(setModalParams({ target: "addToHome" }));
        }}
      >
        {t("modals.addToHome.widget.add")}
      </button>
    </div>
  );
}
