import { SET_CURRENT_USER_AGENT, SET_NAVIGATOR_METADATA } from "./actions";

const initialState = {
  useragent: "",
  metadata: {
    safari: false,
    ios: false,
    android: false,
    standalone: false,
    instagram: false,
    webview: false,
  },
};

interface Action {
  type: string;
  useragent?: string;
  navigator?: {
    userAgent: string;
  };
}

export default function (state = initialState, action: Action = { type: "" }) {
  switch (action.type) {
    case SET_CURRENT_USER_AGENT: {
      return {
        ...state,
        useragent: action.useragent,
      };
    }
    case SET_NAVIGATOR_METADATA: {
      return {
        ...state,
        useragent: action.navigator.userAgent,
        metadata: {
          ...state.metadata,
          safari: action.navigator.userAgent.match(/safari/),
          ios: (/iPhone|iPad|iPod/).test(action.navigator.userAgent),
          android: action.navigator.userAgent.match(/Android/),
          standalone: window.matchMedia("(display-mode: standalone)").matches,
          instagram: action.navigator.userAgent.indexOf("Instagram") > -1,
          webview: action.navigator.userAgent.includes("wv"),
        },
      };
    }
    default:
      return state;
  }
}
