import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { UserAgentProvider } from "@quentin-sommer/react-useragent";
import { MetaFunction, json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  ShouldRevalidateFunction,
  isRouteErrorResponse,
  useLoaderData,
  useMatches,
  useRouteError,
} from "@remix-run/react";
import * as sentry from "@sentry/remix";
import axios from "axios";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relative from "dayjs/plugin/relativeTime";
import httpStatus from "http-status";
import jalali from "jalaliday";
import React from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import reactToastifyStyles from "react-toastify/dist/ReactToastify.css?url";
import reactPopupJsStyles from "reactjs-popup/dist/index.css?url";
import { useChangeLanguage } from "remix-i18next/react";
import { ClientOnly } from "remix-utils/client-only";
import { GlobalLoading } from "~/components/GlobalLoading";
import { ModalContainer } from "~/containers/Modal";
import * as cookies from "~/cookies";
import i18next from "~/i18n.server";
import sharedStyles from "~/styles/shared.css?url";
import MessageCard from "./components/MessageCard";
import Navbar from "./components/Navbar";
import { postLog } from "./services/api";
import createStore from "./services/store";
import tailwindStyles from "./tailwind.css?url";

const { store } = createStore();

dayjs.extend(relative);
dayjs.extend(jalali);
dayjs.extend(duration);

const gtmParams = { id: "GTM-P6T66WLB" };

export function links() {
  return [
    { rel: "stylesheet", href: tailwindStyles },
    { rel: "stylesheet", href: sharedStyles },
    { rel: "stylesheet", href: reactToastifyStyles },
    { rel: "stylesheet", href: reactPopupJsStyles },
    {
      rel: "stylesheet",
      href: "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/css/flag-icons.min.css",
    },
    {
      rel: "stylesheet",
      href: "https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900|Nunito:200,300,400,600,700,800,900|Open+Sans:300,400,600,700,800|Roboto:100,300,400,500,700,900|Google+Sans:300,400,500,700|Iceland|Figtree:300,400,600,700,800,900",
    },
    {
      name: "apple-touch-icon",
      rel: "apple-touch-icon",
      href: "/apple-touch-icon.png?hash=a93",
    },
    {
      name: "icon",
      sizes: "32x32",
      href: "/favicon-32x32.png",
      rel: "icon",
      type: "image/png",
    },
    {
      name: "icon",
      sizes: "16x16",
      href: "/favicon-16x16.png",
      rel: "icon",
      type: "image/png",
    },
    { href: "/site.webmanifest?hash=a9133", rel: "manifest" },
    { href: "/safari-pinned-tab.svg", rel: "mask-icon", color: "#5bbad5" },
  ];
}

export const meta: MetaFunction = () => {
  const { t } = useTranslation("translation");
  return [
    {
      title: t("title"),
      description: t("description"),
    },
    {
      property: "og:title",
      content: t("og.title"),
    },
    {
      property: "og:description",
      content: t("og.description"),
    },
    {
      property: "og:url",
      content: "https://hidsim.com",
    },
    {
      property: "og:image",
      content: "/img/open_graph_logo.png",
    },
    {
      property: "og:image:width",
      content: "1200",
    },
    {
      property: "og:image:height",
      content: "630",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:site_name",
      content: t("og.site_name"),
    },
    {
      property: "twitter:card",
      content: "summary_large_image",
    },
  ];
};

type ErrorBoundaryComponent = (props: {
  children: React.ReactNode;
}) => JSX.Element;
const ErrorBoundaryDocument: ErrorBoundaryComponent = (props: {
  children: React.ReactNode;
}) => {
  const { i18n } = useTranslation();
  return (
    <html dir={i18n.dir()} data-theme="luxury">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta name="theme-color" content="#ffffff" />
        <Meta />
        <Links />
      </head>
      <body>
        {props.children}
        <Scripts />
      </body>
    </html>
  );
};

export const UnWrappedErrorBoundary: ErrorBoundaryComponent = () => {
  const { t } = useTranslation();
  const error: unknown = useRouteError();
  console.log(error);
  sentry.captureRemixErrorBoundaryError(error);

  let status: number | undefined = httpStatus["INTERNAL_SERVER_ERROR"];
  if (isRouteErrorResponse(error)) status = error.status;
  else if (axios.isAxiosError(error)) status = error.response?.status;

  if (status === httpStatus["UNAUTHORIZED"]) {
    return (
      <ErrorBoundaryDocument>
        <MessageCard
          type="error"
          title={t("errors.401")}
          description={t("errors.401_message")}
          toDo={t("reload")}
          toDoHref="/services"
        />
      </ErrorBoundaryDocument>
    );
  }
  if (status === httpStatus["INTERNAL_SERVER_ERROR"]) {
    return (
      <ErrorBoundaryDocument>
        <MessageCard
          type="error"
          title={t("errors.500")}
          description={t("errors.500_message")}
          toDo={t("backToHome")}
          toDoHref="/services"
        />
      </ErrorBoundaryDocument>
    );
  }
  if (status === httpStatus["NOT_FOUND"]) {
    return (
      <ErrorBoundaryDocument>
        <MessageCard
          type="error"
          title={t("errors.404")}
          description={t("errors.404_message")}
          toDo={t("backToHome")}
          toDoHref="/services"
        />
      </ErrorBoundaryDocument>
    );
  }
  if (status === httpStatus["CONFLICT"]) {
    return (
      <ErrorBoundaryDocument>
        <MessageCard
          title={t("errors.409")}
          description={t("errors.409_message")}
          toDo={t("reload")}
          toDoHref="/services"
        />
      </ErrorBoundaryDocument>
    );
  }
  if (status === httpStatus["TOO_MANY_REQUESTS"]) {
    return (
      <ErrorBoundaryDocument>
        <MessageCard
          type="error"
          title={t("errors.429")}
          description={t("errors.429_message")}
          toDo={t("reload")}
          toDoHref="/services"
        />
      </ErrorBoundaryDocument>
    );
  }

  return (
    <ErrorBoundaryDocument>
      <MessageCard
        type="error"
        title={t("errors.500")}
        description={t("errors.500_message")}
        toDo={t("reload")}
        toDoHref="/services"
        // autoReloadAfter={5000}
      />
      <div className="container px-10">
        <h1>Error</h1>
        <p>{error.message}</p>
        <p>The stack trace is:</p>
        <pre>{error.stack}</pre>
      </div>
    </ErrorBoundaryDocument>
  );
};

export const ErrorBoundary = sentry.withSentry(UnWrappedErrorBoundary, {
  wrapWithErrorBoundary: false,
});

type LoaderData = {
  locale: string;
  cookie: unknown;
  ua: string;
  ENV: any;
};
export let loader = async ({ request }) => {
  const locale = await i18next.getLocale(request);
  let cookie = await cookies.auth.find(request);
  if (cookie) cookie = await cookies.auth.refresh(request);

  return json<LoaderData>(
    {
      locale,
      cookie,
      ua: request.headers.get("user-agent"),
      ENV: {
        APP_API_URL: process.env.APP_API_URL,
      },
    },
    {
      headers: [
        [
          "Set-Cookie",
          `${cookie ? await cookies.auth.cookie.serialize(cookie) : ""}; path=/;`,
        ],
        ["Set-Cookie", await cookies.lng.cookie.serialize(locale)],
      ],
    },
  );
};

export const shouldRevalidate: ShouldRevalidateFunction = ({}) => {
  return true;
};

export let handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: "translation",
};

function App() {
  // Get the locale from the loader
  let { locale, ENV, ua, cookie } = useLoaderData<LoaderData>();
  let { i18n } = useTranslation();
  const matches = useMatches();
  const match = matches.find((match) => match.data && match.data.links);
  const links = match?.data.links;
  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale);

  React.useEffect(() => {
    if (!cookie?.token) return;

    const setFp = async () => {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      const data = { fingerprint: visitorId };
      postLog({ token: cookie.token, data }).catch(sentry.captureException);
    };

    setFp();
  }, []);

  React.useEffect(() => {
    if (cookie?.token) localStorage.setItem("auth", cookie.token);
  }, [cookie]);

  return (
    <html lang={locale} dir={i18n.dir()} data-theme="light">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover"
        />
        <meta name="theme-color" content="#ffffff" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-title" content="HidSim" />
        <meta name="apple-touch-fullscreen" content="yes" />

        <Meta />
        <Links />
        {links && links.map((link) => <link {...link} />)}
      </head>
      <body className="overflow-x-hidden relative">
        <GlobalLoading />
        <GTMProvider state={gtmParams} />
        <Provider store={store}>
          <UserAgentProvider ua={ua}>
            <>
              <main className="flex w-[100vw] flex-col min-h-screen">
                <ClientOnly fallback={null}>
                  {() => <Navbar cookie={cookie} />}
                </ClientOnly>
                <Outlet />
                <ModalContainer />
              </main>
              <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={i18n.dir() === "rtl"}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                stacked
                toastClassName="!rounded-xl p-3 w-10 mx-auto"
              />
            </>
          </UserAgentProvider>
        </Provider>
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.process = ${JSON.stringify({ env: ENV })};`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}

export default sentry.withSentry(App);
