import { UAContext } from "@quentin-sommer/react-useragent";
import { useNavigate } from "@remix-run/react";
import classnames from "classnames";
import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Sheet } from "react-modal-sheet";
import { useSelector } from "react-redux";
import Modal from "~/components/Modal";

function DownloadNativeApplication({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: () => void;
}) {
  const { uaResults } = React.useContext(UAContext);
  const { t } = useTranslation();
  const agent = useSelector((state) => state.agent);

  const Content = () => {
    return (
      <div
        className={cx(
          "no-scrollbar relative border-0 bg-base-100 overflow-y-auto",
        )}
        style={{
          paddingBottom: "calc(env(safe-area-inset-bottom) + 2rem)",
        }}
      >
        <div className="mb-5 flex flex-col justify-center items-center gap-4 px-4 pb-4 md:mt-6">
          <div className="font-semibold font-['Hubot-Sans', 'IRANYekanXVF'] text-xl">
            {t("modals.downloadNativeApplication.howToDownload")}
          </div>

          {agent?.metadata.android && (
            <div className="text-center text-neutral-500 text-sm font-normal font-['Figtree', 'IRANYekanXVF'] leading-normal">
              {t("modals.downloadNativeApplication.androidDescription")}
            </div>
          )}

          {agent?.metadata.ios && (
            <div className="text-center text-neutral-500 text-sm font-normal font-['Figtree', 'IRANYekanXVF'] leading-normal">
              {t("modals.downloadNativeApplication.iosDescription")}
            </div>
          )}
        </div>

        <div className="flex w-full flex-col items-center justify-center gap-7 rounded-xl px-4 text-center">
          <button
            onClick={async () => {
              if (agent?.metadata.android) {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.hidsim",
                );
              } else {
                //       window.open("https://play.google.com/store/apps/details?id=ir.mizban.app");
              }
              onClose();
            }}
            className={classnames(
              "btn btn-block text-neutral-900 bg-[#a8efc0] text-base font-semibold font-['Figtree', 'IRANYekanXVF'] leading-normal justify-center text-center rounded-full",
            )}
          >
            {agent?.metadata.android
              ? t("modals.downloadNativeApplication.downloadAndroidApp")
              : t("modals.downloadNativeApplication.downloadIosApp")}
          </button>

          <button
            onClick={async () => {
              window.open("https://t.me/hidsimsupport");
            }}
            className="btn-block text-neutral-900 text-base font-semibold font-['Figtree', 'IRANYekanXVF'] leading-normal"
          >
            {t("support")}
          </button>

          {/* <button
            onClick={onClose}
            className="btn-block text-neutral-900 text-base font-semibold font-['Figtree', 'IRANYekanXVF'] leading-normal"
          >
            {t("okay")}
          </button> */}
        </div>
      </div>
    );
  };

  if (uaResults.mobile) {
    return (
      <Sheet
        isOpen={visible}
        onClose={onClose}
        detent="content-height"
        tweenConfig={{ ease: "easeIn", duration: 0.3 }}
        style={{ zIndex: 50 }}
      >
        <Sheet.Container className="!rounded-t-3xl !bg-base-100">
          <Sheet.Header />
          <Sheet.Scroller>
            <Sheet.Content>
              <Content />
            </Sheet.Content>
          </Sheet.Scroller>
        </Sheet.Container>
        <Sheet.Backdrop className="backdrop-blur-sm" onTap={onClose} />
      </Sheet>
    );
  }
  return (
    <Modal
      isOpen={visible}
      onClose={onClose}
      classNames={{ dialog: "max-w-md" }}
    >
      <Content />
    </Modal>
  );
}

export default DownloadNativeApplication;
