import { motion } from "framer-motion";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Sheet } from "react-modal-sheet";
import LogoBar from "~/components/LogoBar";
import Modal from "~/components/Modal";

export default function AddToHomeIOS({ onDismissAlert, isVisible }) {
  const { t, i18n } = useTranslation();
  return (
    <Sheet
      isOpen={isVisible}
      onClose={onDismissAlert}
      detent="full-height"
      tweenConfig={{ ease: "easeIn", duration: 0.3 }}
      style={{ zIndex: 50 }}
    >
      <Sheet.Container className="!rounded-t-3xl !bg-base-100 mb-0">
        <Sheet.Header />
        <Sheet.Scroller>
          <Sheet.Content>
            <div className="no-scrollbar relative overflow-y-auto">
              <div className="top- w-full flex flex-col justify-center">
                <motion.img
                  src="/img/logo/logo_bg_black_1.png"
                  className="w-24 h-24 mx-auto rounded-3xl my-5"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                />
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1 }}
                >
                  <LogoBar />
                </motion.div>

                <div className="mt-10 py-5 px-7 flex flex-col">
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 1.5 }}
                    className="font-bold mb-2 text-lg"
                  >
                    {t("modals.addToHome.title")}
                  </motion.div>
                  <motion.section
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 2 }}
                    className="flex flex-col gap-4 mb-10"
                  >
                    <p>
                      <Trans
                        t={t}
                        i18n={i18n}
                        i18nKey="modals.addToHome.android.one"
                        components={{
                          b: <b />,
                        }}
                      >
                        1. Tap on <b>3 dots</b> on the top of the screen next to
                        the address bar
                      </Trans>
                      <img
                        src="/img/add_to_home/android_three_dots.png"
                        className="mt-3 rounded-2xl"
                      />
                    </p>
                    <p>
                      <Trans
                        t={t}
                        i18n={i18n}
                        i18nKey="modals.addToHome.android.two"
                        components={{
                          b: <b />,
                        }}
                      >
                        2. Select <b>Add To Home Screen</b> option.
                      </Trans>
                      <img
                        src="/img/add_to_home/android_add_to_home_option.png"
                        className="mt-3 rounded-2xl"
                      />
                    </p>
                    <p>
                      <Trans
                        t={t}
                        i18n={i18n}
                        i18nKey="modals.addToHome.android.three"
                        components={{
                          b: <b />,
                        }}
                      >
                        3. Tap on <b>Install</b> Option.
                      </Trans>
                      <img
                        src="/img/add_to_home/android_install.png"
                        className="mt-3 rounded-2xl"
                      />
                    </p>
                  </motion.section>

                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 3 }}
                    className="flex justify-center w-full"
                  >
                    <button
                      onClick={onDismissAlert}
                      className="btn btn-block btn-primary"
                    >
                      {t("okay")}
                    </button>
                  </motion.div>
                </div>
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Scroller>
      </Sheet.Container>
      <Sheet.Backdrop className="backdrop-blur-sm" onTap={onDismissAlert} />
    </Sheet>
  );
}
