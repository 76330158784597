import { UserAgent } from "@quentin-sommer/react-useragent";
import { Link, useLocation } from "@remix-run/react";
import { useWindowScroll } from "@uidotdev/usehooks";
import classnames from "classnames";
import flag from "country-code-emoji";
import { motion } from "framer-motion";
import lodash from "lodash";
import { ChevronDown, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DocumentStackIcon from "~/assets/svg/DocumentStack";
import HomeIcon from "~/assets/svg/Home";
import UserIcon from "~/assets/svg/User";
import { LANGUAGES } from "~/i18n";
import { setNavigatorMetadata } from "~/services/agent/actions";
import AddToHomeWidget from "./components/AddToHomeWidget";

const ROUTES = [
  { to: "/services", icon: DocumentStackIcon, text: "navbar.services" },
  { to: "/operations", icon: HomeIcon, text: "navbar.myNumbers" },
  { to: "/profile", icon: UserIcon, text: "navbar.profile" },
];
const SVG_PROPS = {
  className:
    "w-6 h-6 scale-110 relative flex-col justify-start items-start flex ",
  fill: "currentColor",
  fillRule: "evenodd",
};

const BLOCK_REGEXP = new RegExp(/\/(register|login|init)/);

interface NavbarProps {
  cookie: string;
}

function Navbar({ cookie }: NavbarProps) {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [{ y }] = useWindowScroll();
  const dispatch = useDispatch();
  const [safeAreaInsets, setSafeAreaInsets] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      const sat = parseFloat(
        getComputedStyle(document.documentElement).getPropertyValue("--sat"),
      );
      const sar = parseFloat(
        getComputedStyle(document.documentElement).getPropertyValue("--sar"),
      );
      const sab = parseFloat(
        getComputedStyle(document.documentElement).getPropertyValue("--sab"),
      );
      const sal = parseFloat(
        getComputedStyle(document.documentElement).getPropertyValue("--sal"),
      );

      setSafeAreaInsets({
        top: sat,
        right: sar,
        bottom: sab,
        left: sal,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(setNavigatorMetadata(navigator));
  }, []);

  if (location.pathname === "/") return null;
  if (BLOCK_REGEXP.test(location.pathname)) return null;

  return (
    <nav>
      <motion.div
        initial={false}
        animate={safeAreaInsets.top > 0 && y > 10 ? "visible" : "hidden"}
        exit="hidden"
        variants={{
          hidden: { opacity: 0, height: 0 },
          visible: {
            opacity: 1,
            height: "calc(env(safe-area-inset-top) + 1rem)",
          },
        }}
        transition={{ duration: 0.1, ease: "easeInOut" }}
        className="fixed inset-x-0 -top-1 z-50 flex items-center border-t border-white border-opacity-10 bg-base-300 bg-opacity-30 text-base-content backdrop-blur-3xl"
      />
      <UserAgent mobile>
        <div className="fixed inset-x-0 bottom-0 z-50 flex flex-col items-center text-base-content">
          <AddToHomeWidget />
          <div className="w-full flex items-center border-t-[0.5px] pb-[calc(env(safe-area-inset-bottom)+1rem)] pt-4 border-base-300/80 bg-base-100/90 text-base-content backdrop-blur-3xl">
            {ROUTES.map((route) => (
              <Link
                key={route.to}
                to={route.to}
                className={classnames(
                  "flex shrink grow basis-0 flex-col items-center justify-center gap-1",
                  {
                    "text-base-content": location.pathname.includes(route.to),
                  },
                )}
              >
                <div
                  className={classnames(
                    "flex items-center justify-center rounded-full px-4 py-1",
                    {
                      "bg-[#a8efc0]": location.pathname.includes(route.to),
                    },
                  )}
                >
                  {route.icon(SVG_PROPS)}
                </div>
                <div
                  className={classnames(
                    "mt-1 text-center text-xs leading-none",
                    { "font-semibold": location.pathname.includes(route.to) },
                  )}
                >
                  {t(route.text)}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </UserAgent>

      <UserAgent tablet computer>
        <nav
          className={` font-semibold font-['Figtree', 'IRANYekanXVF'] fixed top-0 z-30 w-full flex-col justify-center py-3 hidden lg:flex h-20 transition-all duration-300 ease-in-out bg-white/70 backdrop-blur-3xl border-b `}
        >
          <div className="container mx-auto flex max-w-7xl items-center justify-between px-6">
            <Link className="flex items-center gap-4" to="/">
              <div className="text-lg font-extrabold">HidSim</div>
            </Link>
            <div className="flex items-center justify-center gap-4">
              {ROUTES.map((route) => (
                <Link
                  key={route.to}
                  to={route.to}
                  className="flex items-center justify-center gap-2 rounded-md p-3"
                >
                  <div className="text-center text-base text-black">
                    {t(route.text)}
                  </div>
                </Link>
              ))}
            </div>

            <section className="flex gap-8 items-center">
              <div className="dropdown dropdown-end">
                <label tabIndex={0} className="btn btn-ghost rounded-btn">
                  <span className="text-xl">
                    {flag(
                      lodash.find(LANGUAGES, {
                        lng: i18n.language.toLowerCase(),
                      })?.local,
                    )}
                  </span>
                  <span>
                    {
                      lodash.find(LANGUAGES, {
                        lng: i18n.language.toLowerCase(),
                      })?.lng
                    }
                  </span>
                  <ChevronDown size={18} />
                </label>
                <ul
                  tabIndex={0}
                  className="menu dropdown-content rounded-box z-[1] mt-4 w-52 bg-base-100 p-2 shadow"
                >
                  {LANGUAGES.map(({ lng, name, local }) => (
                    <li key={lng}>
                      <a href={`/services/?lng=${lng}`}>
                        {flag(local)} {name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              {cookie?.user && (
                <div className="flex gap-5 items-center">
                  <Link to="/profile">{t("navbar.myAccount")}</Link>
                  <Link
                    className="flex gap-1 items-center rounded-full bg-[#a8efc0] px-4 py-2 uppercase text-sm font-bold"
                    to="/profile"
                    state={{ modal: "charge" }}
                  >
                    <span>{cookie.user.balance}</span>
                    <span className="text-xs text-base-content/40 font-medium uppercase">
                      {t("coins")}
                    </span>
                  </Link>
                </div>
              )}
              {!cookie?.user && (
                <div className="flex gap-5 items-center">
                  <Link to="/profile">{t("navbar.login")}</Link>
                  {location.pathname === "/" && (
                    <Link
                      className="flex gap-1 items-center rounded-full bg-[#a8efc0] px-4 py-2 uppercase text-sm font-bold"
                      to="/services"
                    >
                      {t("navbar.startNow")}
                    </Link>
                  )}
                </div>
              )}
            </section>
          </div>
        </nav>
      </UserAgent>

      <UserAgent tablet computer>
        <div className="shadow-lg fixed ps-5 pe-8 py-6 flex-col gap-5 start-10 bottom-10 rounded-3xl z-50 flex items-start border-t border-white border-opacity-10 bg-base-100/90 text-base-content backdrop-blur-3xl">
          {ROUTES.map((route) => (
            <Link
              key={route.to}
              to={route.to}
              className={classnames("flex items-center justify-center gap-4", {
                "text-base-content": location.pathname.includes(route.to),
              })}
            >
              <div
                className={classnames(
                  "flex items-center justify-center rounded-full px-4 py-1",
                  {
                    "bg-[#a8efc0]": location.pathname.includes(route.to),
                  },
                )}
              >
                {route.icon(SVG_PROPS)}
              </div>
              <div
                className={classnames(
                  "mt-1 text-center text-base leading-none",
                  {
                    "font-semibold": location.pathname.includes(route.to),
                  },
                )}
              >
                {t(route.text)}
              </div>
            </Link>
          ))}
        </div>
      </UserAgent>
    </nav>
  );
}

export default Navbar;
