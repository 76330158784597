import { UAContext } from "@quentin-sommer/react-useragent";
import React from "react";
import { useSelector } from "react-redux";
import Android from "./components/Android";
import IOS from "./components/IOS";

export default function AddToHome({ visible, onClose }) {
  const { uaResults } = React.useContext(UAContext);
  const agent = useSelector((state) => state.agent);

  if (!agent?.metadata) return null;
  if (!uaResults?.mobile) return null;
  if (agent?.metadata?.standalone) return null;
  if (agent?.metadata?.instagram) return null;
  if (agent?.metadata?.webview) return null;

  if (agent?.metadata.safari && agent?.metadata.ios)
    return <IOS onDismissAlert={onClose} isVisible={visible} />;

  return <Android onDismissAlert={onClose} isVisible={visible} />;
}
